export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Bad Parenting Game 2 Online for Free",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
