"use client"
import {useEffect, useState} from "react";
import dynamic from "next/dynamic";

export default function GoogleAnalyticsDefer() {
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        // 页面渲染完成后，设置 showGA 为 true
        setIsShow(true);
    }, []);

    // 动态导入 Google Analytics 组件
    const GoogleAnalytics = dynamic(
        () => import('@next/third-parties/google').then((mod) => mod.GoogleAnalytics),
        {ssr: false, loading: () => null}
    );

    return <>
    {/* bad-parenting-game.com 专属 */}
        {isShow && <GoogleAnalytics gaId="G-XYG89D8H3J"/>}
    </>
}